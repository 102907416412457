import dayjs from "dayjs";

const useFreeSpinQuest = (gameId: Ref<number | undefined> = ref()) => {
	const { questData, questPrizeSum, isFreeSpinQuest, progress, playerDays } = useGameQuest();

	const questDay = computed(() => questData.value?.dayInfo || {});

	const questInfo = computed(() => questData.value?.questInfo || {});

	const activeDay = computed(() => {
		const activeDayIndex = playerDays.value?.findIndex((day) => !day.isLocked && !day.isCompleted);
		return activeDayIndex === -1
			? playerDays.value?.[Number(questDay.value?.day || 1) - 1]
			: playerDays.value?.[activeDayIndex];
	});

	const activeTaskIndex = computed(() => {
		const indexTask = activeDay.value?.tasks?.findIndex(({ isCompleted }) => !isCompleted);
		return indexTask === -1 ? 1 : indexTask + 1;
	});

	const questPrizePool = computed(() =>
		activeDay.value?.tasks?.reduce((accumulator, task) => accumulator + (task?.prize?.freeSpin?.freeSpinCount ?? 0), 0)
	);

	const questTasksCount = computed(() => activeDay.value?.tasks?.length);

	const activeTask = computed(() => {
		const indexTask = questData.value?.dayInfo?.tasks?.findIndex(({ isCompleted }) => !isCompleted);
		return indexTask === -1 ? questData.value?.dayInfo?.tasks?.[0] : questData.value?.dayInfo?.tasks?.[indexTask];
	});

	const isGameInTask = computed(() => {
		if (activeTask.value?.action?.type !== "gamesList" && activeTask.value?.action?.type !== "game") {
			return false;
		}

		return activeTask.value?.action?.details?.games?.some((game) => {
			if (typeof game === "object") {
				return game.id === gameId.value;
			}
			if (typeof game === "number") {
				return game === gameId.value;
			}
			return false;
		});
	});

	const getFormatToken = computed(() =>
		dayjs(questInfo.value?.end ?? "").diff(dayjs(), "d") >= 1 ? "DD[d ] HH[h ]mm[m ]" : "HH[h ] mm[m ] ss[s ]"
	);

	const { durationLeft: durationLeftQuestDay, reset: resetQuestDay } = useCountdown({
		timestamp: questInfo.value?.end || "",
		formatToken: getFormatToken.value,
		isOptimized: true
	});

	const unwatchEndDate = watch(
		() => questInfo.value?.end,
		(value) => {
			resetQuestDay(value || "", getFormatToken.value);
		}
	);

	onBeforeUnmount(() => {
		unwatchEndDate();
	});

	return {
		progress,
		isGameInTask,
		questTasksCount,
		questPrizePool,
		activeTaskIndex,
		activeTask,
		questData,
		questPrizeSum,
		questInfo,
		isFreeSpinQuest,
		activeDay,
		durationLeftQuestDay
	};
};

export default useFreeSpinQuest;
